import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _27a8b116 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _92a4d632 = () => interopDefault(import('../pages/daily_report.vue' /* webpackChunkName: "pages/daily_report" */))
const _1c3a8311 = () => interopDefault(import('../pages/driver_schedule.vue' /* webpackChunkName: "pages/driver_schedule" */))
const _aeecf120 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _7e686422 = () => interopDefault(import('../pages/information.vue' /* webpackChunkName: "pages/information" */))
const _7322121d = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _1dddc428 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c5f10b16 = () => interopDefault(import('../pages/navi_default.vue' /* webpackChunkName: "pages/navi_default" */))
const _1b3386d0 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _7d6c8ca5 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _27a8b116,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/daily_report/",
    component: _92a4d632,
    pathToRegexpOptions: {"strict":true},
    name: "daily_report"
  }, {
    path: "/driver_schedule/",
    component: _1c3a8311,
    pathToRegexpOptions: {"strict":true},
    name: "driver_schedule"
  }, {
    path: "/forget_password/",
    component: _aeecf120,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/information/",
    component: _7e686422,
    pathToRegexpOptions: {"strict":true},
    name: "information"
  }, {
    path: "/inspire/",
    component: _7322121d,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _1dddc428,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/navi_default/",
    component: _c5f10b16,
    pathToRegexpOptions: {"strict":true},
    name: "navi_default"
  }, {
    path: "/reset_password/",
    component: _1b3386d0,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/signup/",
    component: _7d6c8ca5,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/",
    redirect: "/navi_default/",
    name: "index"
  }, {
    path: "*",
    redirect: "/navi_default/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
