
































































































































































































import { computed, createComponent, ref, watch } from '@vue/composition-api'
import { firebaseApp } from '@/plugins/firestore'
import { navItems } from '@/pages/config'

export default createComponent({
  components: {},
  setup(_props, context) {
    const currentItemIdx = ref<number>(0)
    const currentItem = computed(() => {
      return navItems[currentItemIdx.value]
    })
    watch(currentItemIdx, () => {
      if (currentItemIdx.value > 0) {
        context.root.$router.push(currentItem.value.items[0].link)
      }
    })

    // クーポン対応追加
    const currentItemsWebApp = computed(() => {
      const couponUser = context.root.$auth.user
        ? context.root.$auth.user.coupon_user
        : false
      return currentItem.value.items.filter((val) => {
        // クーポンユーザ出ない場合、クーポンメニューを非表示
        if (val.title === '利用規約') {
          val.title = '規約等'
        }
        if (val.title === 'アカウント確認/変更') {
          val.title = 'アカウント設定'
        }
        return (
          !(val.title === 'クーポン' && !couponUser) &&
          !(val.title === 'プライバシーポリシー')
        )
      })
    })

    // クーポン対応追加
    const currentItems = computed(() => {
      const couponUser = context.root.$auth.user
        ? context.root.$auth.user.coupon_user
        : false
      return currentItem.value.items.filter((val) => {
        // クーポンユーザ出ない場合、クーポンメニューを非表示
        return !(val.title === 'クーポン' && !couponUser)
      })
    })

    const logout = async () => {
      await context.root.$auth.logout().catch(console.error)
      await firebaseApp
        .auth()
        .signOut()
        .catch(console.error)
      context.root.$router.push('/login/')
    }

    if (context.root.$route) {
      const path = context.root.$route.path
      if (path.startsWith('/reservations/')) {
        currentItemIdx.value = 1
      } else if (path.startsWith('/account/')) {
        currentItemIdx.value = 2
      } else if (path.startsWith('/service/')) {
        currentItemIdx.value = 3
      }
    }

    const userName = computed(() => {
      return context.root.$auth.user
        ? `${context.root.$auth.user.last_name}${context.root.$auth.user.first_name}`
        : ''
    })

    const userId = computed(() => {
      return context.root.$auth.user ? context.root.$auth.user.id : ''
    })

    const checkSubNav = () => {
      if (currentItem.value === undefined) {
        return 'no-subnav'
      } else {
        return currentItem.value.items.length === 0 ? 'no-subnav' : false
      }
    }

    const checkNavForUndefined = () => {
      return (
        currentItem.value !== undefined && currentItem.value.items.length > 0
      )
    }

    const checkNavForUndefinedSpTab = () => {
      return currentItemIdx.value > 0 && currentItem.value.items.length > 0
    }

    return {
      navItems,
      currentItemIdx,
      currentItem,
      userName,
      userId,
      logout,
      checkSubNav,
      checkNavForUndefined,
      currentItems,
      currentItemsWebApp,
      checkNavForUndefinedSpTab
    }
  }
})
