











import { createComponent, ref } from '@vue/composition-api'
import moment from '~/node_modules/moment'
export default createComponent({
  setup(_props) {
    const formatDate = ref<string>('')
    setInterval(() => {
      formatDate.value = moment().format('YYYY/MM/DD HH:mm:ss')
    }, 1000)

    return {
      formatDate
    }
  }
})
